class CategoryFilter {
  select!: HTMLSelectElement | null
  table!: HTMLTableElement | null

  init (): void {
    this.select = document.querySelector<HTMLSelectElement>('.filter-select')
    this.table = document.querySelector<HTMLTableElement>('.filtered-list')

    if (! this.select || ! this.table) {
      return
    }

    this.bindEvents()
  }

  bindEvents (): void {
    this.select?.addEventListener('change', (): void => {
      this.filterListBySelectOption()
    }, false)
  }

  filterListBySelectOption (): void {
    const selectedOption = this.select?.value

    if (! this.table) {
      return
    }

    for (let i = 1; i < this.table.rows.length; i++) {
      const row = this.table.rows[i]
      const parameter = row.dataset.value

      if (selectedOption === '') {
        row.classList.remove('d-none')
      } else {
        if (parameter !== selectedOption) {
          row.classList.add('d-none')
        } else {
          row.classList.remove('d-none')
        }
      }
    }
  }
}

export {
  CategoryFilter
}
