import { getAncestor } from '@/util/dom'

type FormElement = HTMLInputElement | HTMLTextAreaElement

class CharacterCounter {
  inputs!: NodeListOf<FormElement>

  init (): void {
    this.inputs = document.querySelectorAll<FormElement>('[data-has-character-counter]')
    this.bindEvents()
  }

  bindEvents (): void {
    this.inputs.forEach(input => this.createInputCounter(input))
  }

  createBadge (currentLength: number, maxLength: number): HTMLSpanElement {
    const badge = document.createElement('span')

    badge.classList.add('badge', 'bg-secondary', 'character-counter')
    badge.innerHTML = this.createBadgeText(currentLength, maxLength)

    return badge
  }

  createBadgeText (currentLength: number, maxLength: number): string {
    // Domyślną wartością dla atrybutu maxLength jest -1
    if (maxLength === -1) {
      return currentLength.toString(10)
    }

    return `${currentLength} / ${maxLength}`
  }

  createInputCounter (input: FormElement): void {
    const maxLength = input.maxLength
    const currentLength = input.value.length
    const badge = this.createBadge(currentLength, maxLength)

    input.addEventListener('keyup', (e): void => {
      const currentLength = input.value.length

      badge.innerHTML = this.createBadgeText(currentLength, maxLength)

      if (currentLength >= maxLength) {
        e.preventDefault()
      }
    }, false)

    input.insertAdjacentElement('afterend', badge)
    getAncestor(input, '.info-box-content').classList.add('character-counter__container')
  }
}

export {
  CharacterCounter
}
