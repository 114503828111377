/**
 * Znajduje w DOM pierwszy element nadrzędny do podanego, zgadzający się
 * z podanym selektorem.
 */
const getAncestor = <T extends HTMLElement, U extends HTMLElement>(elem: T, selector: string): U => {
  let parent = elem.parentNode

  while (parent !== document) {
    if ((parent as Element).matches(selector)) {
      return parent as U
    }

    parent = parent ? parent.parentNode : null
  }

  throw new Error(`Podany element nie ma rodzica zgodnego z selektorem ${selector}`)
}

export {
  getAncestor
}
