class AlertTools {
  elements!: NodeListOf<HTMLElement>

  init (): void {
    this.elements = document.querySelectorAll<HTMLElement>('[data-alert]')

    this.bindEvents()
  }

  /**
   * Przypisuje zdarzenia do elementów.
   *
   * Nie musimy wewnątrz elementów sprawdzać, czy `data-x` istnieje, bo selektory to wymuszają
   */
  bindEvents (): void {
    this.elements.forEach(element => {
      element.addEventListener('click', e => {
        this.onAlertActionClick(e, element)
      }, false)
    })
  }

  onAlertActionClick (e: Event, element: HTMLElement): void {
    e.preventDefault()

    alert(element.dataset.alert)
  }
}

export {
  AlertTools
}
