import { captureMessage } from '@sentry/browser'
import { httpPost } from '@/util/fetch'

class FileRemoveTool {
  elements!: NodeListOf<HTMLElement>

  init (): void {
    this.elements = document.querySelectorAll<HTMLButtonElement>('[data-file-remove-url]')

    this.bindEvents()
  }

  /**
   * Przypisuje zdarzenia do elementów.
   */
  bindEvents (): void {
    this.elements.forEach(element => {
      element.addEventListener('click', async (): Promise<void> => {
        await this.editHandler(element)
      }, false)
    })
  }

  /**
   * Nie trzeba sprawdzać, czy data-x istnieje, bo to robi za nas selector
   */
  async editHandler (element: HTMLElement): Promise<void> {
    const url = element.dataset.fileRemoveUrl as string

    const response = await httpPost(url)

    if (!response.ok) {
      captureMessage('Wystąpił błąd usuwania plików', {
        extra: {
          response: await response.text()
        },
        level: 'error'
      })

      return
    }

    element.closest<HTMLElement>('.file-remove-parent')?.remove()
  }
}

export {
  FileRemoveTool
}
