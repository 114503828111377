const httpGet = async (
  url: string,
  request: Omit<RequestInit, 'body' | 'headers'> = {},
  headers: Record<string, string> = {}
): Promise<Response> => {
  return await fetch(url, {
    method: 'GET',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      ...headers
    },
    ...request
  })
}

export {
  httpGet
}

