import { getElementSafe } from '@/util/dom'

class HelpBoxActivator {
  activators!: NodeListOf<HTMLButtonElement>
  boxes!: Array<HTMLElement>

  init (): void {
    this.activators = document.querySelectorAll<HTMLButtonElement>('[data-help-box]')
    this.boxes = this.getHelpBoxes()

    this.bindEvents()
  }

  bindEvents (): void {
    this.activators.forEach((activator, index): void => {
      activator.addEventListener('click', (): void => {
        this.onActivatorClick(activator, index)
      }, false) 
    })
  }

  onActivatorClick (activator: HTMLButtonElement, index: number): void {
    this.toggleAriaExpanded(activator)
    this.toggleHelpBox(index)
  }

  toggleAriaExpanded (activator: HTMLButtonElement): void {
    activator.ariaExpanded = activator.ariaExpanded === 'true' ? 'false' : 'true'
  }

  toggleHelpBox (index: number): void {
    this.boxes[index].classList.toggle('help-box--shown')
  }

  private getHelpBoxes (): Array<HTMLElement> {
    return [...this.activators].map(activator => {
      // Nie musimy sprawdzać czy atrybut istnieje, robi to za nas selektor
      // pobierający aktywatory
      const id = activator.dataset.helpBox as string

      return getElementSafe(`#${id}`)
    })
  }
}

export {
  HelpBoxActivator
}
