class TabFocusManager {
  init (): void {
    this.bindEvents()
  }

  bindEvents (): void {
    document.addEventListener('keydown', (): void => {
      this.enableFocusStyles()
    })

    document.addEventListener('mousedown', (): void => {
      this.disableFocusStyles()
    })
  }

  enableFocusStyles (): void {
    document.body.classList.add('tab-focus')
  }

  disableFocusStyles (): void {
    document.body.classList.remove('tab-focus')
  }
}

export {
  TabFocusManager
}
