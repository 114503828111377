import { A11yToggle } from '@/scripts/global/a11y-toggle'
import { AlertTools } from '@/scripts/global/alert-tools'
import { CategoryFilter } from '@/scripts/global/category-filter'
import { CharacterCounter } from '@/scripts/form/character-counter'
import { ConfirmTools } from '@/scripts/global/confirm-tools'
import { FileRemoveTool } from '@/scripts/global/file-remove-tool'
import { HelpBoxActivator } from '@/scripts/global/help-box-activator'
import { HighContrastToggle }  from '@/scripts/global/high-contrast-toggle'
import { MenuKeyboardController } from '@/scripts/global/menu-keyboard-controller'
import { StyleToggle } from '@/scripts/global/style-toggle'
import { TabFocusManager } from '@/scripts/global/tab-focus-manager'
import { TextEditor } from '@/scripts/global/text-editor'
// import { TextSize } from '@/scripts/global/text-size'

const a11yToggle = new A11yToggle()
const alertTools = new AlertTools()
const categoryFilter = new CategoryFilter()
const characterCounter = new CharacterCounter()
const confirmTools = new ConfirmTools()
const fileRemoveTool = new FileRemoveTool()
const helpBoxActivator = new HelpBoxActivator()
const highContrastToggle = new HighContrastToggle()
const menuKeyboardController = new MenuKeyboardController()
const styleToggle = new StyleToggle()
const tabFocusManager = new TabFocusManager()
const textEditor = new TextEditor()
// const textSize = new TextSize()

document.addEventListener('DOMContentLoaded', (): void => {
  a11yToggle.init()
  alertTools.init()
  categoryFilter.init()
  characterCounter.init()
  confirmTools.init()
  fileRemoveTool.init()
  helpBoxActivator.init()
  highContrastToggle.init()
  menuKeyboardController.init()
  styleToggle.init()
  tabFocusManager.init()
  textEditor.init()
  // textSize.init()
}, false)
