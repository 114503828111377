const httpPost = async (
  url: string,
  request: Omit<RequestInit, 'headers'> = {},
  headers: Record<string, string> = {}
): Promise<Response> => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      ...headers
    },
    ...request
  })
}

export {
  httpPost
}
