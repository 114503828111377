const isSupported = (): boolean => {
  if (typeof Storage !== 'undefined' && window.localStorage !== undefined) {
    try {
      localStorage.setItem('localStorageTest', '1')
      localStorage.removeItem('localStorageTest')
    } catch (e) {
      return false
    }
  }

  return true
}

export {
  isSupported
}
