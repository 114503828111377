// Sentry
import '@/sentry'

// Zależności AdminLTE
import 'overlayscrollbars'

// SortTable
import 'sortable-tablesort/sortable'

// AdminLTE
import 'admin-lte/src/ts/layout'
import 'admin-lte/src/ts/push-menu'
import 'admin-lte/src/ts/treeview'
// import 'admin-lte/src/ts/direct-chat'
// import 'admin-lte/src/ts/card-widget'

// Bootstrap
import '@/bootstrap'

// Główne style aplikacji
import '#/app.scss'

// skrypty globalne
import '@/entries/global'
