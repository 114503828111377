import EasyMDE from 'easymde'

class TextEditor {
  init (): void {
    this.bindEvents()
  }

  bindEvents (): void {
    const textArea = document.querySelector('.text-editor') as HTMLTextAreaElement | undefined
    if (!textArea) {
      return
    }

    new EasyMDE({
      status: ['words', 'cursor'],
      element: textArea,
      toolbarButtonClassPrefix: 'text-dark ',
      hideIcons: ['guide', 'image', 'side-by-side'],
      spellChecker: false
    })
  }
}

export {
  TextEditor
}
