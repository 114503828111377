import { getElementSafe } from '@/util/dom'

class A11yToggle {
  toggleButton!: HTMLButtonElement
  stylesheet!: HTMLLinkElement

  init (): void {
    const toggle = document.querySelector<HTMLButtonElement>('#a11y-style-toggle')
    if (toggle) {
      this.toggleButton = toggle
      this.stylesheet = getElementSafe<HTMLLinkElement>('#a11y-style')
      this.bindEvents()
    }
  }

  bindEvents (): void {
    this.toggleButton.addEventListener('click', (): void => {
      this.stylesheet.removeAttribute('disabled')
    }, false)
  }
}

export {
  A11yToggle
}
