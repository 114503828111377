import { getElementSafe } from '@/util/dom'
import { Key } from '@/util/event/key'

/**
 * Bardzo prosta obsługa klawiatury dla bocznego menu.
 *
 * Spacja wyzwala kliknięcie elementu tak samo, jak klawisz Enter.
 * Kliknięcie elementu zmienia atrybut aria-expanded.
 */
class MenuKeyboardController {
  navigation!: HTMLElement
  submenuActivators!: NodeListOf<HTMLAnchorElement>

  init (): void {
    this.navigation = getElementSafe('#sidebar-navigation')
    this.submenuActivators = this.navigation.querySelectorAll<HTMLAnchorElement>('.sidebar-menu > li > a')

    this.bindEvents()
  }

  bindEvents (): void {
    this.submenuActivators.forEach(item => {
      item.addEventListener('click', (): void => {
        this.onActivatorClick(item)
      })

      item.addEventListener('keydown', e => {
        this.onActivatorKeyDown(e, item)
      }, false)
    })
  }

  onActivatorKeyDown (e: KeyboardEvent, activator: HTMLAnchorElement): void {
    // Nie musimy obsługiwać 'Enter', kliknięcie jest automatycznie wyzwalane
    // po jego wciśnięciu
    if (e.key === Key.Space) {
      activator.click()
    }
  }

  onActivatorClick (activator: HTMLAnchorElement): void {
    activator.ariaExpanded = activator.ariaExpanded === 'true' ? 'false' : 'true'
  }
}

export {
  MenuKeyboardController
}
