class StyleToggle {
  toggleButton!: HTMLButtonElement

  init (): void {
    const toggle = document.querySelector<HTMLButtonElement>('#style-toggle')
    if (toggle) {
      this.toggleButton = toggle
      this.bindEvents()
    }
  }

  bindEvents (): void {
    this.toggleButton.addEventListener('click', (): void => {
      this.toggleStyles()
    }, false)
  }

  toggleStyles (): void {
    for (const stylesheet of document.styleSheets) {
      stylesheet.disabled = !stylesheet.disabled
    }
  }
}

export {
  StyleToggle
}
