const getElementSafe = <T extends Element = Element>(selector: string): T => {
  // Jeżeli selektor zawiera spacje, znaczy, że jest złożony i nie możemy przekazać go do
  // funkcji document.getElementById
  const isIdSelector = selector.startsWith('#') && selector.split(' ').length === 1

  const element: T | null = isIdSelector
    ? document.getElementById(selector.substring(1)) as T | null
    : document.querySelector<T>(selector)

  if (!element) {
    throw new DOMException(`Brak elementu ${selector}`)
  }

  return element
}

export {
  getElementSafe
}
