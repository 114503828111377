class ConfirmTools {
  elements!: NodeListOf<HTMLElement>

  init (): void {
    this.elements = document.querySelectorAll<HTMLElement>('[data-confirm]')

    this.bindEvents()
  }

  /**
   * Przypisuje zdarzenia do elementów.
   *
   * Nie musimy wewnątrz elementów sprawdzać, czy `data-x` istnieje, bo selektory to wymuszają
   */
  bindEvents (): void {
    this.elements.forEach(element => {
      element.addEventListener('click', e => {
        this.onConfirmActionClick(e, element)
      }, false)
    })
  }

  onConfirmActionClick (e: Event, element: HTMLElement): void {
    if (!confirm(element.dataset.confirm)) {
      e.preventDefault()
    }
  }
}

export {
  ConfirmTools
}
