import { init, type BrowserOptions, BrowserTracing } from '@sentry/browser'

if (__SENTRY_DSN__ !== '' && __SENTRY_DSN__ !== null) {
  const options: BrowserOptions = {
    dsn: __SENTRY_DSN__,
    integrations: [
      new BrowserTracing()
    ],
    release: window.SENTRY_RELEASE.id,
    tracesSampleRate: .3
  }

  init(options)
}
