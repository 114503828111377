import { getElementSafe } from '@/util/dom'
import { isLocalStorageSupported } from '@/util/local-storage'

class HighContrastToggle {
  private localStorageKey = 'high-contrast'

  active = false
  toggle!: HTMLButtonElement

  constructor () {
    if (isLocalStorageSupported()) {
      this.active = localStorage.getItem(this.localStorageKey) === 'true'
      this.handleAttribute()
    }
  }

  init (): void {
    this.toggle = getElementSafe<HTMLButtonElement>('#high-contrast-toggle')
    this.bindEvents()
  }

  bindEvents (): void {
    this.toggle.addEventListener('click', (): void => {
      if (this.active) {
        this.disableHighContrastMode()
      } else {
        this.enableHighContrastMode()
      }
    })
  }

  disableHighContrastMode (): void {
    this.active = false
    this.saveToLocalStorage()
    this.handleAttribute()
  }

  enableHighContrastMode (): void {
    this.active = true
    this.saveToLocalStorage()
    this.handleAttribute()
  }

  private handleAttribute (): void {
    if (this.active) {
      document.documentElement.dataset.highContrast = '1'
      if (this.toggle) {
        this.toggle.title = 'Wersja podstawowa'
      }
    } else {
      document.documentElement.dataset.highContrast = '0'
      if (this.toggle) {
        // domyslna wartość w konstruktorze, gdy this.element jeszcze nie jest zainicjalizowany
        // zmieniając kontrast wcześnie, minimalizujemy FOUC ale nie można zmienić title
        this.toggle.title = 'Wersja kontrastowa'
      }
    }
  }

  private saveToLocalStorage (): void {
    localStorage.setItem(this.localStorageKey, String(this.active))
  }
}

export {
  HighContrastToggle
}
